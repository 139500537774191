import _ from "lodash";
import { DEFAULT_FRAMERATE, VideoQuality } from "../constants";
import {
  HLS_VIEWER_ROLE,
  INTERNAL_RECORDER_ROLE,
} from "../helpers/roles_helper";
import { isCustomPublishParams } from "../helpers/utils";

export const roleTemplate = {
  role: "",
  permissions: {
    mute: false,
    unmute: false,
    removeOthers: false,
    endRoom: false,
    changeRole: false,
    lockRoom: false,
    sendRoomState: true,
  },
  subscribeDegradation: true,
  priority: 1,
  maxPeerCount: 0,
  chat: {
    privateMessage: [],
  },
  screenshare: {
    permissions: [],
  },
  annotation: {
    permissions: [],
  },
  virtualBackgrounds: {
    set: true,
    upload: false,
  },
  spotlight: {
    permissions: [],
  },
  raiseHand: {
    raiseOwn: false,
    lowerOthers: true,
  },
  googleDrive: {
    permissions: [],
  },
  youtube: {
    permissions: [],
  },
  advancedSettings: {
    publish: {
      audio: true,
      video: true,
      screenshare: true,
      params: {
        video: {
          bitRate: 300,
          frameRate: DEFAULT_FRAMERATE,
          codec: "vp8",
        },
        screenshare: {
          bitRate: null,
          frameRate: 10,
          codec: "vp8",
        },
        simulcast: {},
        videoSimulcastLayers: {},
        screenSimulcastLayers: {},
      },
    },
    subscribe: {
      to: [],
    },
  },
  video_quality: "_720p",
  screenshare_quality: "_1080p",
  default: false,
  video_aspect_ratio: "4:3",
};

export const roleSimulcastConfig = {
  "120:120": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 60,
          maxFramerate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "160:120": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 100,
          maxFramerate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "213:120": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 110,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "120:160": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 100,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "120:213": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 110,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "240:240": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 156,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "320:240": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 200,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "426:240": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 240,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "240:320": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 200,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "240:426": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 240,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "360:360": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 200,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "480:360": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 300,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 120,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "640:360": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 400,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 150,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "360:480": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 300,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 120,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "360:640": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 400,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 150,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "480:480": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 250,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 130,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "640:480": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 400,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 160,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "854:480": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 500,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 200,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "480:640": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 400,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 160,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "480:854": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 500,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 200,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "720:720": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 450,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 200,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "q",
          scaleResolutionDownBy: 4,
          maxBitrate: 80,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "960:720": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 700,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 250,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "q",
          scaleResolutionDownBy: 4,
          maxBitrate: 100,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "1280:720": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 850,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 300,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "q",
          scaleResolutionDownBy: 4,
          maxBitrate: 120,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "720:960": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 700,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 250,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "q",
          scaleResolutionDownBy: 4,
          maxBitrate: 100,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  "720:1280": {
    video: {
      layers: [
        {
          rid: "f",
          scaleResolutionDownBy: 1,
          maxBitrate: 850,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "h",
          scaleResolutionDownBy: 2,
          maxBitrate: 300,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
        {
          rid: "q",
          scaleResolutionDownBy: 4,
          maxBitrate: 120,
          maxFrameRate: DEFAULT_FRAMERATE,
        },
      ],
    },
    screen: {},
  },
  // "1080:1080": {
  //   video: {
  //     layers: [
  //       {
  //         rid: "f",
  //         scaleResolutionDownBy: 1,
  //         maxBitrate: 800,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "h",
  //         scaleResolutionDownBy: 2,
  //         maxBitrate: 325,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "q",
  //         scaleResolutionDownBy: 4,
  //         maxBitrate: 150,
  //         maxFramerate: 30,
  //       },
  //     ],
  //   },
  //   screen: {},
  // },
  // "1440:1080": {
  //   video: {
  //     layers: [
  //       {
  //         rid: "f",
  //         scaleResolutionDownBy: 1,
  //         maxBitrate: 1200,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "h",
  //         scaleResolutionDownBy: 2,
  //         maxBitrate: 400,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "q",
  //         scaleResolutionDownBy: 4,
  //         maxBitrate: 175,
  //         maxFramerate: 30,
  //       },
  //     ],
  //   },
  //   screen: {},
  // },
  // "1920:1080": {
  //   video: {
  //     layers: [
  //       {
  //         rid: "f",
  //         scaleResolutionDownBy: 1,
  //         maxBitrate: 1300,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "h",
  //         scaleResolutionDownBy: 2,
  //         maxBitrate: 500,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "q",
  //         scaleResolutionDownBy: 4,
  //         maxBitrate: 225,
  //         maxFramerate: 30,
  //       },
  //     ],
  //   },
  //   screen: {},
  // },
  // "1080:1440": {
  //   video: {
  //     layers: [
  //       {
  //         rid: "f",
  //         scaleResolutionDownBy: 1,
  //         maxBitrate: 1200,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "h",
  //         scaleResolutionDownBy: 2,
  //         maxBitrate: 400,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "q",
  //         scaleResolutionDownBy: 4,
  //         maxBitrate: 175,
  //         maxFramerate: 30,
  //       },
  //     ],
  //   },
  //   screen: {},
  // },
  // "1080:1920": {
  //   video: {
  //     layers: [
  //       {
  //         rid: "f",
  //         scaleResolutionDownBy: 1,
  //         maxBitrate: 1300,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "h",
  //         scaleResolutionDownBy: 2,
  //         maxBitrate: 500,
  //         maxFramerate: 30,
  //       },
  //       {
  //         rid: "q",
  //         scaleResolutionDownBy: 4,
  //         maxBitrate: 225,
  //         maxFramerate: 30,
  //       },
  //     ],
  //   },
  //   screen: {},
  // },
};

/**
 * function to return video config for given quality and aspect ratio
 * @param {string} videoQuality
 * @param {string} aspectRatio
 * @returns {Object}
 */
export const getVideoParamsForQuality = (videoQuality, aspectRatio) =>
  VideoQuality[videoQuality][aspectRatio];

/**
 *
 * function to return simulcast config for given quality and aspect ratio
 * @returns {import("src/types/policyTypes").roleTemplatePolicy.publishParams.simulcast}
 */
export const getSimulcastConfigForVideoQuality = ({
  videoQuality = "",
  aspectRatio = "",
}) => {
  if (!videoQuality || isCustomPublishParams(videoQuality)) {
    return {};
  }
  const videoParam = getVideoParamsForQuality(videoQuality, aspectRatio);
  if (!videoParam) {
    return {};
  }
  const { width = 0, height = 0 } = videoParam;
  const simulcastConfigKey = `${width}:${height}`;
  const { video = {}, screen = {} } =
    roleSimulcastConfig[simulcastConfigKey] || {};
  return {
    video: { ...video } || {},
    screen: { ...screen } || {},
  };
};

/**
 *
 * this is a function built purely for UI
 * given simulcast layers / config are verbose and are essentially input to Webrtc layer,
 * to make it simple / readable, we convert them to resolution layers for users to understand it easily.
 * @returns
 */
export const getSimulcastLayersForConfig = ({
  layers,
  videoQuality,
  aspectRatio,
}) => {
  if (!layers) {
    return {
      qualityLayerMap: {},
      simulcastQualities: [],
    };
  }

  let videoParam = {};
  if (Object.keys(VideoQuality).includes(videoQuality)) {
    videoParam = getVideoParamsForQuality(videoQuality, aspectRatio);
  } else {
    return {
      qualityLayerMap: {},
      simulcastQualities: [],
    };
  }
  const { height, width } = videoParam;
  const qualityLayerMap = layers.reduce((acc, layer) => {
    const { scaleResolutionDownBy = 1 } = layer;
    const minDim = Math.min(height, width);
    const qualityLayer = minDim / scaleResolutionDownBy;
    const qualityLayerName = `${qualityLayer}p`;
    acc[qualityLayerName] = layer;
    return acc;
  }, {});
  const simulcastQualities = Object.keys(qualityLayerMap);
  return {
    qualityLayerMap,
    simulcastQualities,
  };
};

const policyInfoRoleTemplate = {
  name: "",
  publishParams: {
    allowed: [],
    audio: {
      bitRate: 32,
      codec: "opus",
    },
    video: {
      bitRate: 300,
      codec: "vp8",
      frameRate: DEFAULT_FRAMERATE,
      width: 480,
      height: 360,
    },
    screen: {
      codec: "vp8",
      frameRate: 10,
      width: 1920,
      height: 1080,
    },
    videoSimulcastLayers: {},
    screenSimulcastLayers: {},
  },
  subscribeParams: {
    subscribeToRoles: [],
    maxSubsBitRate: 3200,
    subscribeDegradation: {
      packetLossThreshold: 25,
      degradeGracePeriodSeconds: 1,
      recoverGracePeriodSeconds: 4,
    },
  },
  permissions: {
    rtmpStreaming: false,
    hlsStreaming: false,
    browserRecording: false,
    sendRoomState: true,
  },
  priority: 1,
  maxPeerCount: 0,
};

/**
 * @returns {import("src/types/policyTypes").roleTemplatePolicy}
 */
export function getInternalRecoderPolicyInfoRole({
  roleName = INTERNAL_RECORDER_ROLE,
  subscribeToRoles = [],
} = {}) {
  const template = _.cloneDeep(policyInfoRoleTemplate);
  template.name = roleName;
  template.permissions.rtmpStreaming = false;
  template.permissions.hlsStreaming = false;
  template.permissions.browserRecording = false;
  template.subscribeParams.subscribeToRoles = subscribeToRoles || [];
  return template;
}

/**
 * @returns {import("src/types/policyTypes").roleTemplatePolicy}
 */
export function getHlsViewerPolicyInfoRole(name) {
  const template = _.cloneDeep(policyInfoRoleTemplate);
  template.name = name || HLS_VIEWER_ROLE;
  return template;
}

export default roleTemplate;

export function getEmptyTemplate({ roleName = "" }) {
  const template = _.cloneDeep(roleTemplate);
  template.role = roleName;
  template.advancedSettings.publish.audio = false;
  template.advancedSettings.publish.video = false;
  template.advancedSettings.publish.screenshare = false;
  return template;
}

export function getInternalRecorderTemplate() {
  const roleInfo = getEmptyTemplate({
    roleName: INTERNAL_RECORDER_ROLE,
  });
  roleInfo.permissions = {
    ...roleInfo.permissions,
    rtmpStreaming: true,
    hlsStreaming: true,
    browserRecording: false,
  };
  return roleInfo;
}

export function getHlsViewerTemplate() {
  const roleInfo = getEmptyTemplate({ roleName: HLS_VIEWER_ROLE });

  if (process.env.REACT_APP_ENABLE_STREAMING_PERMISSIONS === "true") {
    roleInfo.permissions = {
      ...roleInfo.permissions,
      rtmpStreaming: false,
      hlsStreaming: false,
      browserRecording: false,
    };
  }

  return roleInfo;
}
